<template>
  <div></div>
</template>

<script>
export default {
  name: "Account",
  data() {
    return {
      user: null,
      custom:{},
    }
  },
  async created() {
    window.location.href = 'https://app.egcextremeunrealtechnology.com/web#action=766&model=res.users&view_type=form';
  },
}
</script>